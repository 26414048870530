.header {
  text-align: center;
  padding: 0.2rem 1rem;
}

.header h1, .header h2 {
  font-weight: 200;
  margin: 0;
}

.header h1 {
  font-size: 3rem;
}

.header h2 {
  font-size: 2rem;
  margin-bottom: .3rem;
}

/* Tablet */
@media screen and (max-width: 992px) {

}

/* Mobile */
@media screen and (max-width: 600px) {
  .header h1 {
    font-size: 2.2rem;
  }

  .header h2 {
    font-size: 1.6rem;
  }
}
.menu {
  width: 600px;
  text-align: center;
  display: table;
  margin: 0 auto;
}
.menu ul {
  list-style-type: none;
  display:table-row;
}

.menu .menu-item {
  display: inline-block;
  min-width: 120px;
  height: 100px;
  display: table-cell;
  vertical-align: middle;
  text-transform: uppercase;
  color: #252525;
}

.menu .menu-item a {
  color: #252525;
  text-decoration: none; 
  font-weight: bold;
}

.menu .menu-item a:hover {
  text-decoration: underline;
}

.menu .countdown a:hover {
  text-decoration: none;
}

.menu .countdown div {
  width: 100px;
  height: 100px;
  border: 6px solid #f68e56;
  border-radius: 50%;
}

.menu .countdown p {
  position: relative;
  top: 50%;
  margin: 0;
  margin-top: 3px;
  -webkit-transform: perspective(1px) translateY(-50%);
          transform: perspective(1px) translateY(-50%);
  font-size: 0.8rem;
}

.menu .countdown span {
  display: block;
}

/* Tablet */
@media screen and (max-width: 992px) {

}

/* Mobile */
@media screen and (max-width: 600px) {
  .menu {
    position: relative;
    width: 100%;
    padding: 1.2rem 0 0;
  }

  .menu .menu-item {
    font-size: 0.8rem;
    min-width: 73px;
    height: 50px;
    padding-top: 2rem;
  }

  .menu .countdown {
    position: absolute;
    top: -44px;
    left: 50%;
    margin-left: -30px;
  }

  .menu .countdown div {
    width: 60px;
    height: 60px;
    border: 4px solid #f68e56;
  }

  .menu .countdown p {
    font-size: 0.4rem;
  }

  .menu .countdown .huge-text {
    font-size: 2.6rem;
    line-height: 2.3rem;
  }
}
.operan {
  width: 500px;
}

.info.info-high {
  height: 1400px;
}
.info {
  width: 790px;
  height: 100vh;
  min-height: 100%; 
  margin: 0 auto;
  background-color:rgba(0, 0 , 0, 0.3);
  padding-bottom: 10rem;
}

.info, .info .menu .menu-item a {
  color: #fff;
}

.bg-overlay {
  position: fixed;
  width: 790px;
  height: 100px;
  margin: 0 auto;
  z-index: 0;
  background-color:rgba(0, 0 , 0, 0.3);
}

/* Tablet */
@media screen and (max-width: 992px) {

}

/* Mobile */
@media screen and (max-width: 600px) {
  .info {
    width: 100%;
  }
}
html, body{
  height:100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Open Sans', sans-serif;
}

h1, h2, h3 {
  font-family: 'Dancing Script', cursive;
  margin: 0 0 0.2rem 0;
}

a {
  color: #fff;
}

p {
  margin: 0 0 1rem;
}

.text-color--orange {
  color: #f7941d;
}

.huge-text {
  font-size: 4rem;
  line-height: 3rem;
}

img.bg {
  min-height: 100%;
  min-width: 1024px;
  width: 100%;
  height: auto;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
}

.content {
  width: 690px;
  margin: 0 auto;
  padding: 1rem;
  box-sizing: border-box;
}

.content-title {
  font-size: 2rem;
}

.content-sub-title {
  font-size: 1.5rem;
}



@media screen and (max-width: 1024px) { /* Specific to this particular image */
  img.bg {
    left: 27%;
    margin-left: -512px;   /* 50% */
  }
}

/* Tablet */
@media screen and (max-width: 992px) {

}

/* Mobile */
@media screen and (max-width: 600px) {
  .content {
    max-width: 100%;
    padding-top: 0.3rem;
  }
}
