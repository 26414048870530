.header {
  text-align: center;
  padding: 0.2rem 1rem;
}

.header h1, .header h2 {
  font-weight: 200;
  margin: 0;
}

.header h1 {
  font-size: 3rem;
}

.header h2 {
  font-size: 2rem;
  margin-bottom: .3rem;
}

/* Tablet */
@media screen and (max-width: 992px) {

}

/* Mobile */
@media screen and (max-width: 600px) {
  .header h1 {
    font-size: 2.2rem;
  }

  .header h2 {
    font-size: 1.6rem;
  }
}