.menu {
  width: 600px;
  text-align: center;
  display: table;
  margin: 0 auto;
}
.menu ul {
  list-style-type: none;
  display:table-row;
}

.menu .menu-item {
  display: inline-block;
  min-width: 120px;
  height: 100px;
  display: table-cell;
  vertical-align: middle;
  text-transform: uppercase;
  color: #252525;
}

.menu .menu-item a {
  color: #252525;
  text-decoration: none; 
  font-weight: bold;
}

.menu .menu-item a:hover {
  text-decoration: underline;
}

.menu .countdown a:hover {
  text-decoration: none;
}

.menu .countdown div {
  width: 100px;
  height: 100px;
  border: 6px solid #f68e56;
  border-radius: 50%;
}

.menu .countdown p {
  position: relative;
  top: 50%;
  margin: 0;
  margin-top: 3px;
  transform: perspective(1px) translateY(-50%);
  font-size: 0.8rem;
}

.menu .countdown span {
  display: block;
}

/* Tablet */
@media screen and (max-width: 992px) {

}

/* Mobile */
@media screen and (max-width: 600px) {
  .menu {
    position: relative;
    width: 100%;
    padding: 1.2rem 0 0;
  }

  .menu .menu-item {
    font-size: 0.8rem;
    min-width: 73px;
    height: 50px;
    padding-top: 2rem;
  }

  .menu .countdown {
    position: absolute;
    top: -44px;
    left: 50%;
    margin-left: -30px;
  }

  .menu .countdown div {
    width: 60px;
    height: 60px;
    border: 4px solid #f68e56;
  }

  .menu .countdown p {
    font-size: 0.4rem;
  }

  .menu .countdown .huge-text {
    font-size: 2.6rem;
    line-height: 2.3rem;
  }
}