html, body{
  height:100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Open Sans', sans-serif;
}

h1, h2, h3 {
  font-family: 'Dancing Script', cursive;
  margin: 0 0 0.2rem 0;
}

a {
  color: #fff;
}

p {
  margin: 0 0 1rem;
}

.text-color--orange {
  color: #f7941d;
}

.huge-text {
  font-size: 4rem;
  line-height: 3rem;
}

img.bg {
  min-height: 100%;
  min-width: 1024px;
  width: 100%;
  height: auto;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
}

.content {
  width: 690px;
  margin: 0 auto;
  padding: 1rem;
  box-sizing: border-box;
}

.content-title {
  font-size: 2rem;
}

.content-sub-title {
  font-size: 1.5rem;
}



@media screen and (max-width: 1024px) { /* Specific to this particular image */
  img.bg {
    left: 27%;
    margin-left: -512px;   /* 50% */
  }
}

/* Tablet */
@media screen and (max-width: 992px) {

}

/* Mobile */
@media screen and (max-width: 600px) {
  .content {
    max-width: 100%;
    padding-top: 0.3rem;
  }
}