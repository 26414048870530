.info {
  width: 790px;
  height: 100vh;
  min-height: 100%; 
  margin: 0 auto;
  background-color:rgba(0, 0 , 0, 0.3);
  padding-bottom: 10rem;
}

.info, .info .menu .menu-item a {
  color: #fff;
}

.bg-overlay {
  position: fixed;
  width: 790px;
  height: 100px;
  margin: 0 auto;
  z-index: 0;
  background-color:rgba(0, 0 , 0, 0.3);
}

/* Tablet */
@media screen and (max-width: 992px) {

}

/* Mobile */
@media screen and (max-width: 600px) {
  .info {
    width: 100%;
  }
}